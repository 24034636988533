// zh_CN.js
import React from "react";

const zh_CN = {
//components


    "XiaDanTiXing":"下单提醒",
    "NH_GDDBH_":"您好，该订单包含“预估价”商品，需等待客户确认报价后再付款，确定要下单吗",
    "QueRenXiaDan":"确认下单",
    "WoZaiKanKan":"我再看看",

    "XSQBDZ": "显示全部地址",
//ChatRoom
    "ZBZCBQFH": "暂不支持表情符号",
    "FaSongLianJie": "发送链接",
    "YiChangXiaoXi": "异常消息",
    "QingShuRu": "请输入",
    "FaSong": "发送",
//DateSelector
    "DQSJXYKSSJ": "当前时间小于开始时间",
    "DQSJCGJSSJ": "当前时间超过结束时间",
    "QuXiao": "取消",
    "NianYueRi": "年月日",
    "ShiFen": "时分",
    "QueDing": "确定",
    "Nian": "年",
    "Yue": "月",
    "Ri": "日",
    "Shi": "时",
    "Fen": "分",
//goods-list-card
    "Qi": "起",
//goods-promotion
    "1GeCuXiao": "1个促销",
    "ChaKanZengPin": "查看赠品",
    "JHDJSHS_1T": "距活动结束还剩：{d}天{h}小时{m}分{s}秒",
    "YHGZ_ZPBKD": "优惠规则：赠品不可叠加获取",
    "YHGZ_AZZGM": "优惠规则：按最终购买数量享受优惠",
    "GuanBi": "关闭",
//group-filtrate
    "QuanBu": "全部",
    "ZWXGSJ": "暂无相关数据",
    "ZuiDiJia": "最低价",
    "ZuiGaoJia": "最高价",
    "LeiXing": "类型",
    "PinPai": "品牌",
    "BiaoQian": "标签",
    "JiaGeQuJian": "价格区间",
    "SPMC_GG_BH_": "商品名称 / 规格 / 编号 / 首字母",
    "ZuHeShaiXuan": "组合筛选",
    "ChongZhi_CanShu": "重置",
//image-upload
    "ZDKSC1ZTP": "最多可上传{num}张图片",
    "ZYXSCTP": "只允许上传图片",
    "SCTPDXBYXC": "上传图片大小不允许超过{size}",
    "QBYCFSCTP_": "请不要重复上传图片，或请确保图片名称唯一",
    "ShangChuanChengGong": "上传成功",
    "ZDKSC1Z": "最多可上传{num}张",
//keyboard
    "CCSLZDXZ": "超出数量最大限制",
    "QiDingLiang": "起订量",
    "ShouQi": "收起",
    "ShanChu": "删除",
//MultiPicker
    "FanHui": "返回",
//order-cart
    "Dai": "代",
    "YiQuXiao": "已取消",
    "ZengPin": "赠品",
    "1Zhong": "{num}种",
    "1Jian": "1件",
    "HeJi": "合计",
    "DaiFu": "待付",
//order-promotion
    "_DDM1YZ1J_": "【订单满{targetPrice}元赠{presentNum}件】",
    "_DDM1YLJ1Y_": "【订单满{targetPrice}元立减{price}元】",
    "_DDM1YLX1Z_": "【订单满{targetPrice}元立享{discount}折】",
    "DingDanCuXiao": "订单促销",
//page-ad
    "DLCKJG": "登录查看价格",
//page-floating
    "DianPuZhuYe": "店铺主页",
    "GouWuChe": "购物车",
    "ZaiXianKeFu": "在线客服",
    "GeRenZhongXin": "个人中心",
    "LianXiDianHua": "联系电话",
    "FanHuiDingBu": "返回顶部",
//page-null
    "ZBDYML": "找不到页面了",
    "KNWZCW_HZY": "可能网址错误，或者页面被删除",
    "FanHuiShangJi": "返回上级",
    "FanHuiShouYe": "返回首页",
//product-edit
    "SPQDBNWK": "商品清单不能为空",
    "WoZhiDaoLe": "我知道了",
    "SFQRSCSP": "是否确认删除商品",
    "QueRen": "确认",
    "ZYXSRSZBQB": "只允许输入数字并且不超过2位小数",
    "ShangPinDanJia": "商品单价",
    "YSPYJ": "原商品原价",
    "QSRZDJG": "请输入指定价格",
    "ShuLiang": "数量",
    "DanWei": "单位",
    "DanJia": "单价",
    "BeiZhu": "备注",
    "YiChuShangPin": "移除商品",
    "XiuGai": "修改",
//yunshl
    "TPSLCCYXSZ": "图片数量超出允许数值！",
    "WJBDDY5M_QC": "文件不得大于5m，请重新选择",
    "TPSCWC": "图片上传完成",
//BusinessFilter
    "_DGSLM1JZ1J": "【订购数量满{num}件赠{presentNum}件】",
    "_M1JLXMJ1Y_": "【满{num}件立享每件{price}元】",
    "_DHJJZ1Y_": "【订货价降至{price}元】",
    "_M1JLXMJ1Z_": "【满{num}}件立享每件{discount}折】",
    "_YDHJD1Z_": "【原订货价打{discount}折】",
    "YuJi": "预计",
    "MMYDDR": "MM月DD日",
    "KaiShiFaHuo": "开始发货",
    "_DGSLM1Z1_": "【订购数量满{num}赠{presentNum}】",
    "_M1LXM1Y_": "【满{num}立享每{price}元】",
    "_M1LXM1Z_": "【满{num}立享每{discount}折】",
    "DaiQueRen": "待确认",
    "DaiFaHuo": "待发货",
    "DaiShouHuo": "待收货",
    "YiWanCheng": "已完成",
    "DaiFuKuan": "待付款",
    "YiFuKuan": "已付款",
    "YiZuoFei": "已作废",
    "DaiShenHe": "待审核",
    "DaiHeXiao": "待核销",
    "YiHeXiao": "已核销",
    "XianXiaZhiFu": "线下支付",
    "ZhiNengDaoZhangShiBie": "线下汇款(智能到账识别)",
    "WeiXinZhiFu": "微信支付",
    "ZFBZF": "支付宝支付",
    "YuEZhiFu": "余额支付",
    "HuoDaoFuKuan": "货到付款",
    "DaiTuiHuo": "待退货",
    "DaiTuiKuan": "待退款",
    "ChuangJianDingDan": "创建订单",
    "BianJiDingDan": "编辑订单",
    "TiJiaoDingDan": "提交订单",
    "DingDanShenHe": "订单审核",
    "CaiWuShenHe": "财务审核",
    "BoHui": "驳回",
    "TianJiaShouKuan": "添加收款",
    "ZaiXianZhiFu": "线上支付（支持微信/支付宝/京东支付/云闪付）",
    "MercadoPagoZhiFu": "MercadoPago支付",
    "ShouKuanShenHe": "收款审核",
    "ShouKuanZuoFei": "收款作废",
    "ChuKu": "出库",
    "ChuKuShenHe": "出库审核",
    "FaHuo": "发货",
    "TianJiaWuLiu": "添加物流",
    "XiuGaiWuLiu": "修改物流",
    "ChuKuZuoFei": "出库作废",
    "ShouHuo": "收货",
    "DingDanHeZhun": "订单核准",
    "QuXiaoDingDan": "取消订单",
    "CJTHD": "创建退货单",
    "DaYin": "打印",
    "DaoChu": "导出",
    "DingDanWanCheng": "订单完成 ",
    "QiangZhiWanCheng": "强制完成",
    "DingDanZuoFei": "订单作废",
    "MaiZeng": "买赠",
    "ZhiJiang": "直降",
    "DaZhe": "打折",
    "YuShou": "预售",
    "DingDanManZeng": "订单满赠",
    "DingDanManJian": "订单满减",
    "DingDanManZhe": "订单满折",
    "XinRenQuan": "新人券",
    "ManJianQuan": "满减券",
    "ShangPinQuan": "商品券",
    "DaiJieSuan": "待结算",
    "YiJieSuan": "已结算",
    "QuanBuShangPin": "全部商品",
    "TuiJianShangPin": "推荐商品",
    "XinPin": "新品",
    "ReXiaoShangPin": "热销商品",
    "ChangYongShangPin": "常用商品",
//BusinessUtil
    "DaiShouKuan": "待收款",
    "BuFenShouKuan": "部分收款",
    "YiShouKuan": "已收款",
    "BXYFP": "无需发票",
    "ZZSPTFP": "增值税普通发票",
    "ZZSZYFP": "增值税专用发票",
    "QiTaFaPiao": "其他发票",
    "XianXia": "线下",
    "WeiXin": "微信",
    "ZhiFuBao": "支付宝",
    "YuCunKuan": "预存款",
    "XianXiaZhuangZhang": "线下转账",
    "XianShangZhiFu": "线上支付",
    "YCKZF": "预存款支付",
    "DaiTiJiao": "待提交",
    "KuaiDi": "快递",
    "SongHuoShangMen": "送货上门（不含上楼及安装）",
    "SongHuoShangMen_": "送货上门并安装",
    "ShangMenZiQu": "物流点自提",
    "WuLiuHuoYun": "物流货运",
    "QiTa": "其他",


//check-util

    "QSRBXY6WDM": "请输入不小于6位的密码",
    "QSRBDY20WDM": "请输入不大于20位的密码",
    "MMGSCW": "密码格式错误",


//common-utils

    "SJCW_QLXKF": "数据错误，请联系客服",
    "ZBZCQD": "暂不支持签到",
    "HuJiao": "呼叫",
    "DLZHBCZ": "登录账号不存在",
    "QLXGLYKT": "请联系管理员开通",
    "GQYZHBJY": "该企业账号被禁用",
    "QLXYSDH": "请联系云上订货",
    "GQYZHSYQJS": "该企业账号试用期结束",
    "GQYZHYGQL": "该企业账号已过期了",
    "XYXFCNSY": "需要续费才能使用",
    "GQYBCZCZH": "该企业不存在此帐号",
    "NDDHZHYBQY": "您的订货账号已被企业1禁用，不可登录。请联系管理员",
    "NYBSQY1DHZ": "您已不是企业1的合作客户，不可登录。请联系管理员",
    "NDDPYBTY_B": "您的店铺已被停用，不可登录。请联系管理员",
    "GZHWKTDSZB": "该账号未开通灯饰专版功能。请联系管理员",
    "WXSQYZYGQ": "微信授权验证已过期",
    "WXSQSB": "微信授权失败",
    "WXSQSB_CWX": "微信授权失败,错误信息",
    "DengLuGuoQi": "登录过期",
    "DLGQ_QCXDL": "登录过期，请重新登录",


//data-constant

    "ShangJia": "上架",
    "YiXiaJia": "已下架",
    "YiJinYong": "已禁用",
    "YiQiYong": "已启用",
    "YiTingYong": "已停用",
    "KeHuZhuCe": "客户注册",
    "XinZengDaoRu": "新增导入",
    "DDDSH": "待订单审核",
    "DCWSH": "待财务审核",
    "DaiChuKu": "待出库",
    "DCK_BF_": "待出库（部分）",
    "DFH_BF_": "待发货(部分)",
    "DSH_BF_": "待收货（部分）",
    "DSK_BF_": "待收款（部分）",
    "WTHSQ": "无退货申请",
    "THDSH": "退货待审核",
    "THCLZ": "退货处理中",
    "THYWC": "退货已完成",
    "DYDHD": "打印订货单",
    "DYFHD": "打印发货单",
    "DCDHD": "导出订货单",
    "DCFHDWE_P": "导出发货单为Excel/PDF文件",
    "TuiDanZuoFei": "退单作废",
    "DTHSH": "待退货审核",
    "DaiRuKu": "待入库",
    "DRK_BF_": "待入库（部分）",
    "DFK_BF_": "待付款（部分）",
    "ChuangJianTuiDan": "创建退单",
    "BianJiTuiDan": "编辑退单",
    "TiJiaoTuiDan": "提交退单",
    "TuiHuoShenHe": "退货审核",
    "RuKu": "入库",
    "RuKuShenHe": "入库审核",
    "RuKuZuoFei": "入库作废",
    "TianJiaFuKuan": "添加付款",
    "FuKuanZuoFei": "付款作废",
    "FuKuanShenHe": "付款审核",
    "TuiDanWanCheng": "退单完成",
    "QiTaChuKu": "其他出库",
    "XiaoShouChuKu": "销售出库",
    "PanKuiChuKu": "盘亏出库",
    "DiaoBoChuKu": "调拨出库",
    "CaiGouTuiHuo": "采购退货",
    "TuiHuoRuKu": "退货入库",
    "CaiGouRuKu": "采购入库",
    "QiTaRuKu": "其他入库",
    "PanYingRuKu": "盘盈入库",
    "DiaoBoRuKu": "调拨入库",
    "CJCGD": "创建采购单",
    "BJCGD": "编辑采购单",
    "TJCGD": "提交采购单",
    "CGDZF": "采购单作废",
    "CGDWC": "采购单完成",
    "CGTHZF": "采购退货作废",
    "CGTHWC": "采购退货完成",
    "THDDY": "退货单打印",
    "THDDC": "退货单导出",
    "CKDDY": "出库单打印",
    "CKDDC": "出库单导出",
    "GLDZH": "管理端账号",
    "DHDZH": "订货端账号",
    "ShenHeTongGuo": "审核通过",
    "SHBTG": "审核不通过",
    "WuXuFaPiao": "无需发票",
    "DaiKeXiaDan": "代客下单",
    "ZiZhuXiaDan": "自主下单",
    "PCShangCheng": "PC商城",
    "APPShangCheng": "APP商城",
    "TVShangCheng": "TV商城",
    "WeiXinShangCheng": "微信商城",
    "ShouJiShangCheng": "手机商城",
    "ADDXSMLTC": "按订单销售毛利提成",
    "ADDXSSRTC": "按订单销售收入提成",
    "AKHSSYG": "按客户所属员工",
    "AnZhiDanRen": "按制单人",
    "ATHJEBLKCT": "按退货金额比例扣除提成金额",
    "DDYTHBJXTC": "订单有退货不进行提成",
    "DaiDaKuan": "待打款",
    "WeiKaiShi": "未开始",
    "JinXingZhong": "进行中",
    "YiGuoQi": "已过期",


//Utils.js

    "YouHuo": "有货",
    "WuHuo": "无货",
    "Yi": "亿",
    "QianWan": "千万",
    "BaiWan": "百万",
    "Wan": "万",
    "Sheng1Tian": "剩1天",
    "Sheng1XiaoShi": "剩1小时",
    "Miao": "秒",
    "1Miao": "1秒",
    "GangGang": "刚刚",
    "1FenZhongQian": "1分钟前",
    "1XiaoShiQian": "1小时前",
    "1TianQian": "1天前",
    "1GeYueQian": "1个月前",
    "1NianQian": "1年前",
    "WeiZhiLeiXing": "未知类型",


//WechatUtil

    "YYGBCDSP_K": "有一个不错的商品，快来看一下",
    "ZanWeiSheZhi": "暂未设置",


//app

    "HQWXSQXX": "获取微信授权信息",


//flexible

    "JGJYYDMBQ": "将根据已有的meta标签来设置缩放比例",


//index

    "NLJZZ": "努力加载中",


//routes

    "ShangPinMingXi": "商品明细",
    "ShangPinLeiBie": "商品类别",
    "QXZFPNR": "请选择发票内容",
    "QXZFPLX": "请选择发票类型",
    "QTXFPTT": "请填写发票抬头",
    "FaPiaoTaiTou": "发票抬头",
    "NSRSBH": "纳税人识别号",
    "FaPiaoNeiRong": "发票内容",
    "FaPiaoXinXi": "发票信息",
    "KaiJuFaPiao": "开具发票",
    "FaPiaoLeiXing": "发票类型",
    "Han": "含",
    "WeiHan": "未含",
    "Shui": "税",
    "QSRFPTT": "请输入发票抬头",
    "QSRNSRSBH": "请输入纳税人识别号",


//routes
//cart-bill

    "YiManZu_Man": "已满足【满",
    "ZaiGou": "再购",
    "YMZ_DHJJZ": "已满足【订货价降至",
    "JianJiangZhi": "件降至",
    "YMZ_YDHJD": "已满足【原订货价打",
    "JianMeiJian": "件每件",
    "SYTCSLW0_WFJRGWC": "剩余套餐数量为0，无法加入购物车",
    "QingXuanXeShangPin": "请选择商品！",
    "GSPYXJ": "该商品已下架！",
    "ZJ2TN": "最近2天内",
    "QDQCXJSP": "确定清除下架商品？",
    "QingChuChengGong": "清除成功",
    "TCSPBKJRGWC_QQXTCSPZYRGW": "套餐商品不可加入购物车，请取消套餐商品再移入购物车！",
    "SPSLBN": "商品数量不能",
    "TaoCanHan": "套餐含",
    "TaoCan": "套餐",
    "QHDSL": "请核对数量",
    "SPSLBNDY": "商品数量不能低于",
    "XianDingLiang": "限订量",
    "YiRuShouCang": "移入收藏",
    "QuanXuan": "全选",
    "JianZeng": "件赠",
    "JianLiXiang_Man": "件立享【满",
    "Yuan_": "元】",
    "Zhe_": "折】",
    "KCBZ_WFJRGWC": "库存不足，无法加入购物车",
    "XDJEDYDY": "下单金额大于等于",
    "WYXJHYSXDSP": "无已下架或已失效的商品！",
    "DYQDL": "低于起订量",
    "ZSPG1J": "{count} 种商品,共 {number} 件",
    "WanCheng": "完成",
    "BianJi": "编辑",
    "You": "有",
    "ZSPBFHYQ_W": "种商品不符合要求，未被勾选，点击查默认地址看",
    "SPSLBNGY": "商品数量不能高于",
    "JXDJ_CKXYGBFHYQSP": "继续点击，查看下一个不符合要求商品",
    "XiaDan": "下单",
    "JianJiang": "件降",
    "QRYCSP": "确认移除商品？",
    "QXZXDSP": "请选择下单商品",
    "KCBZ_QCXXZ": "库存不足，请重新选择！",
    "KuCun": "库存",
    "YCKXD": "元才可下单",
    "QDSCSP": "确定收藏商品？",
    "ShouCangChengGong": "收藏成功",
    "GYXDL": "高于限订量",
    "LiSheng": "立省",
    "GWCHSKD_QGYGB": "购物车还是空的，去逛一逛吧",
    "QuGuangGuang": "去逛逛",
    "QCXJSP": "清除下架商品",
    "ZongE": "总额",
    "YouHui": "优惠",


//cart-list

    "QXZJHRQ": "请选择交货日期",
    "FuJiaFeiYong": "附加费用",
    "ZhongShangPin_Gong": "种商品，共",
    "PeiSongShiJian": "配送时间",
    "QXZPSSJ": "请选择配送时间",
    "DingDanJinE": "订单金额",
    "SCFJ_ZD4Z": "上传附件，最多{num}张，单张不超过{size}",
    "KeYongShangPin": "可用商品",
    "ShiYongShuoMing": "使用说明",
    "YouXiaoQi": "有效期",
    "Man": "满",
    "BZZBZCTSFH": "备注暂不支持特殊符号",
    "QingXuanZe": "请选择",
    "KaiPiaoXuJia": "开票需加",
    "PeiSongFangShi": "配送方式",
    "ShangPinZongJia": "商品总价",
    "YouHuiQuan": "优惠券",
    "ZhangKeXan": "张可选",
    "DDJEWMZ": "订单金额未满足",
    "WDYHQ": "我的优惠券",
    "KYYHQ": "可用优惠券",
    "YuanKeYong": "元可用",
    "ChaKanDingDan": "查看订单",
    "ShangPinHanShui": "商品含税",
    "SPWHS": "商品未含税",
    "GeDian": "个点",
    "QueRenDingDan": "确认订单",
    "XZSHDZ": "新增收货地址",
    "ShangPinQingDan": "商品清单",
    "WKYYHQ": "无可用优惠券",
    "HuChi": "互斥",
    "DaiJinQuan": "代金券",
    "YunFei": "运费",
    "ShuiFei": "税费",
    "WKYYY": "不可用原因",
    "YHQYGQ": "优惠券已过期",
    "WDYHQSYSJ": "未到优惠券使用时间",
    "ZDSPJEWMZ": "指定商品金额未满足",
    "BKYYHQ": "不可用优惠券",


//cart-presale-order

    "QXZPSFS": "请选择配送方式",
    "1ZSP_G": "1种商品，共",
    "YSSP_YJ": "预售商品，预计",
    "JiaoHuoRiQi": "交货日期",
    "GMJLY": "给卖家留言",


//scan-to-cart

    "ZhengZaiChuLi...": "正在处理...",
    "FSCSP": "非商城商品",
    "ZZCLTXM": "正在处理条形码...",
    "SPTJCG": "商品添加成功",
    "QSTXM": "请扫条形码",
    "SFSCDQSP": "是否删除当前商品？",
    "BQ_ZBDXGSP": "抱歉，找不到相关商品",
    "XuanZeDanWei": "选择单位",
    "JRGWC": "加入购物车",
    "QXXZDJRSP": "请先选择待加入商品",
    "ZhengZaiChuLi": "正在处理",
    "JRGWCCG": "加入购物车成功",
    "ZanWuShangPin": "暂无商品",


//earn-brokerage-share

    "XTYJBNFZSP": "系统已经帮您复制商品链接！",
    "NKYHDDYDYJ": "你可以获得对应的佣金。佣金可在“我的",
    "LiChaKan": "里查看",
    "WeiXinHaoYou": "微信好友",
    "ErWeiMa": "二维码",
    "CASBEWM": "长按识别二维码",
    "FZSB_QCXFZ": "复制失败，请重新复制！",
    "TWEWM": "图文二维码",
    "BCDXC": "保存到相册",
    "CATPBCDXC": "长按图片保存到相册",
    "FuZhiLianJie": "复制链接",
    "FXZYJ": "分享赚佣金",
    "YJZGKZQYJ": "预计最高可赚取佣金",


//goods-share

    "FXSPGLSKH": "分享商品给零售客户",
    "FXHJSCNZSD": "分享后将生成您专属的手机微商城，您可以设置商城内商品的价格，填写您的联系方式，吸引消费者上门购买。",
    "WSCDQJG_ND": "微商城当前价格：您的订货价 ×",
    "DaiSheZhi": "待设置",
    "PengYouQuan": "朋友圈",
    "GGFXSZ": "更改分享设置",


//wechat-login

    "QXSRZH": "请先输入账号",
    "MMGSCW_6_20W": "密码格式错误(6-20位字母或数字)",
    "BDYYZH": "绑定已有账号",
    "WXKJDL": "微信快捷登录",
    "BangDingZhangHao": "绑定账号",
    "YYZH_QDL": "已有账号，去登录",
    "ZCXZH": "注册新账户",
    "HuanYingNi": "欢迎你",
    "ZH_SJHM": "账号/手机号码",
    "MiMa": "密码",
    "QDLHCZ": "请登录后操作",


//add-to-cart

    "KCBZ_BNTJG": "库存不足，不能添加更多",


//combined-pay-online

    "ZhiFuChengGong": "支付成功！",
    "ZFSB_QCXCS": "支付失败，请重新尝试！",


//

    "QXXZGG": "请先选择规格",
    "SXGGX": "所选规格需",
    "GMSLXYQDL_": "购买数量小于起订量，请重新购买！",
    "GMSLDYXDL_": "购买数量大于限订量，请重新购买！",
    "ShangPinBianHao": "商品编号",
    "QiDing": "起订",
    "SXGGDGSLBK": "所选规格订购数量不可超过",
    "KCBZ_BNTJ": "库存不足，不能添加",
    "QXXZSP": "请先选择商品",
    "SLBNW0": "数量不能为0",
    "QXZGG": "请选择规格",
    "LiJiYuDing": "立即预订",


//nav

    "ShouYe": "首页",
    "ShangPin": "商品",
    "DingDan": "订单",
    "WoDe": "我的",


//pay-online

    "ZhiFuQuXiao": "支付取消",


//account-log

    "ShangPinYongJin": "商品佣金",
    "YaoQingJiangLi": "邀请奖励",
    "TiXian": "提现",
    "TiXianBoHui": "提现驳回",
    "ZhuanWeiYuE": "转为余额",
    "Jin7Tian": "近7天",
    "Jin30Tian": "近30天",
    "ShouZhiMingXi": "收支明细",
    "ZanWuShuJu": "暂无数据",


//client-statistics

    "WoDeKeHu": "我的客户",
    "WoDeYaoQing": "我的邀请",


//distribution-home

    "LeiJiKeHu": "累计客户",
    "LeiJiDingDan": "累计订单",
    "YaoQingHaoYou": "邀请好友",
    "LeiJiYaoQing": "累计邀请",
    "LJSY_Y_": "累计收益（元）",
    "BHDJS": "包含待结算",
    "J7TSY_Y_": "近7天收益（元）",
    "J7TXZKH_R_": "近7天新增客户(人)",
    "KTXYJ": "可提现佣金",
    "XYJYO": "需要加油哦",
    "ChangJianWenTi": "常见问题",
    "Q_ZMYJX_TG": "Q：怎么样进行“推广赚钱",
    "A_FXDPND_Z_": "A：分享店铺内带\"赚\"图标的页面链接，吸引好友点击，好友注册（客户关系设置为注册即绑定）或下单（客户关系设置为下单绑定）在该店铺中购买带\"赚\"图标的商品，都会按照一定的计算方法算作你的“推广赚钱”收益。",
    "Q_RHJS_TGZ": "Q：如何计算“推广赚钱”收益？",
    "A_MGD_Z_TBD": "A：每个带\"赚\"图标的商品都有一定的提成比例，没有该标记的表示该商品不参与“推广赚钱”。好友通过你的分享链接成功付款后，将根据订单中每个商品的提成比例分别计算提成（不参与推广的提成为0），最后累加成为该笔订单的提成收益。收益计算中会扣除运费、退款、满减等金额，以最终成交价为准。",
    "Q_TGZQ_DSY": "Q：“推广赚钱”的收益何时到账？",
    "A_HYTGNDFX": "A：好友通过你的分享链接成功付款后，你可在销售中心的推广订单里看到这笔推广，状态为待结算，客户在确认收货后订单交易完成结算,这笔收益将自动转入你的个人账户，随时可提现。",
    "Q_LJKH_LJY": "Q：累计客户、累计邀请列表没头像昵称？",
    "A_WXYQZYGZ": "A：微信要求只有关注认证服务号才能获取头像昵称，店铺是认证服务号的客户关注店铺公众号，非认证服务号关注“云上订货”公众号后点击店铺任意商品详情页即可获取头像昵称。",
    "Q_WSMWASSG": "Q：为什么我按上述规则分享，没有拿到推广收益？",
    "A_1_HYKNBW": "A：1、好友可能并未与你建立客户关系（好友点击了其他销售员的链接进行注册或下单了）2、好友若在微信环境外（如手机默认浏览器）打开你的分享链接，若浏览器未开启cookie，也将无法记录客户关系，即无法记录推广订单3、上述都没问题时仍然没有记录推广订单，请联系工作人员",


//

    "XiaoShouZhongXin": "销售中心",


//generalize-goods

    "SPMC_GG_BH": "商品名称 / 规格 / 编号",
    "YuJiZhuan": "预计赚",


//invitation-home

    "YaoQingKa": "邀请卡",
    "YNYQJR_TGY": "邀你一起加入，推广赢奖励",
    "DJBCDXC": "点击保存到相册",
    "JYQKFSGHY_": "将邀请卡发送给好友，推广店铺有回报",


//join-home

    "HuanYingJiaRu": "欢迎加入",
    "YQNCW": "邀请你成为",
    "LiJiJiaRu": "立即加入",


//order-statistics

    "YuJiYongJin": "预计佣金",
    "YongJin": "佣金",
    "YuJiJiangLi": "预计奖励",
    "JiangLi": "奖励",
    "SPTGDD": "商品推广订单",
    "YQJLDD": "邀请奖励订单",


//performance-statistics

    "YeJiTongJi": "业绩统计",
    "LJKH_R_": "累计客户（人）",
    "SPYJ_Y_": "商品佣金（元）",
    "HanDaiJieSuan": "含待结算",
    "XGMCJS": "相关名词解释",
    "KeHu": "客户",
    "HFXYBDLKHG": "和分销员绑定了客户关系的买家或分销员",
    "YaoQing": "邀请",
    "FXYCGYQDXJ": "分销员成功邀请的下级分销员",
    "FXYTJKHGMH": "分销员推荐客户购买后，分销员可以获得的佣金",
    "XJFXYTGSPH": "下级分销员推广商品后，上级获得的奖励",


//posters-promoting

    "NiCheng": "昵称",
    "HaiBaoTuiGuang": "海报推广",
    "JHBFSGHY_T": "将海报发送给好友，推广店铺有回报",


//settlement-home

    "JieSuanZhongXin": "结算中心",
    "KTXJE": "可提现金额",
    "TiXianJiLu": "提现记录",


//withdraw-account

    "KHRXMBNWK": "开户人姓名不能为空！",
    "KHHBNWK": "开户行不能为空！",
    "YHKHBNWK": "银行卡号不能为空！",
    "SFZTPBNWK": "身份证图片不能为空！",
    "YHKTPBNWK": "银行卡图片不能为空！",
    "BaoCunChengGong": "保存成功！",
    "SCSFZ": "上传身份证",
    "SCYHK": "上传银行卡",
    "BaoCun": "保存",
    "TiXianZhangHu": "提现账号",
    "TS_QTXZSXX": "提示：请填写真实信息，审核通过后才可提现",
    "KHRXM": "开户人姓名",
    "KaiHuHang": "开户行",
    "YinhangKaHao": "银行卡号",
    "QSRZSXM": "请输入真实姓名",
    "QSRKHHMC": "请输入开户行名称",
    "QSRYHKH": "请输入银行卡号",


//withdraw-deposit

    "QXSZTXZH": "请先设置提现账号",
    "QuSheZhi": "去设置",
    "JEXY10Y_BKT": "金额小于10元，不可提现！",
    "JECGKTXJE": "金额超过可提现金额！",
    "CGDRKTXSX_": "超过当日可提现上限，单日提现上限为20000元！",
    "QueDingJiang": "确定将",
    "ZhuanDaoYuE": "转到余额?",
    "1_JEDY10YSB": "1、金额低于10元时不可提现；",
    "2_BCTXDZSJ": "2、本次提现到账时间为发起提现申请成功后的5个工作日内，单日提现上限为20000元；",
    "3_GBSRWHSS": "3、该笔收入为含税收入，请自行前往相关税务机构进行个人所得税的申报与缴纳！",
    "4_ZDYEKYYS": "4、转到余额可用于商城购物使用；",
    "TXSQCG_QDD": "提现申请成功，请等待审核",
    "JEDYKTXJE_": "金额大于可提现金额，不可转至余额！",
    "ZZYECG_QDG": "转账余额成功，请到个人中心查看！",
    "BenCiTiXian": "本次提现",
    "ZhuangDaoYuE": "转到余额",
    "TiXianDao": "提现到",
    "JEW0BKZZYE": "金额为0不可转至余额！",
    "DRTXSX2WY": "单日提现上限2万元",
    "QuanBuTiXian": "全部提现",
    "QueRenTiXian": "确认提现",


//goods-filter-tab

    "ZuiChangGouMai": "最常购买",
    "MoRenPaiXu": "默认排序",
    "ZongHe": "综合",
    "FenLei": "分类",
    "XiaoLiangYouXian": "销量优先",
    "XiaoLiang": "销量",
    "JiaGe": "价格",
    "ShaiXuan": "筛选",
    "ZuiJinGouMai": "最近购买",
    "CuXiaoYouXian": "促销优先",
    "QuanBuFenLei": "全部分类",
    "ZhiKanCuXiao": "只看促销",
    "XuanZeFenLei": "选择分类",
    "QXZFL": "请选择分类",


//goods-list-default

    "ZWXGSP": "暂无相关商品",


//goods-brand-list

    "PinPaiHui": "品牌汇",


//goods-classify-filter

    "TuiJianFenLei": "推荐分类",
    "ShangPinFenLei": "商品分类",
    "GengDuo": "更多",


//goods-detail

    "DLZHBSDQQY": "登录账号不是当前企业客户账号",
    "GXNHQ": "恭喜您获取",
    "SZWSCJGH_C": "设置微商城价格后，才可分享",
    "JHDJSHS_": "距活动结束还剩",
    "DLHKFX": "登录后可分享",
    "ZZHQXX_QSH": "正在获取信息，请稍候重试",
    "Zhang": "张",
    "Tian": "天",
    "QXZGGZQPD": "请选择规格再去配灯！",
    "Yuan": "元",
    "XS_F_M": "小时/分/秒",


//integral

    "WoDeJiFen": "我的积分",
    "DaBaiLe": "打败了",
    "JiFen": "积分",
    "DeHuiYuan": "的会员",
    "ZhuanJiFen": "赚积分",
    "JiFenMingXi": "积分明细",
    "HuanLiPin": "换礼品",
    "CKGDHL": "查看更多好礼",
    "ChaDingDan": "查订单",


//integral-convert

    "DHSLBNCG": "兑换数量不能超过",
    "DuiHuanShuLiang": "兑换数量",
    "SuoXuJiFen": "所需积分",


//integral-detail

    "LiPinXiangQing": "礼品详情",
    "WoYaoDuiHuan": "我要兑换",
    "ShengYu": "剩余",
    "TuWenXiangQing": "图文详情",
    "YiDuiWan": "已兑完",


//integral-filter

    "FenLeiShaiXuan": "分类筛选",
    "JiFenShaiXuan": "积分筛选",


//integral-flow

    "JiFenShouRu": "积分收入",
    "JiFenZhiChu": "积分支出",


//integral-logistics

    "DingDanGenZong": "订单跟踪",
    "WuLiuGongSi": "物流公司",
    "WuLiuDanHao": "物流单号",


//integral-order

    "DingDanLieBiao": "订单列表",
    "YiFaHuo": "已发货",
    "JiFenDingDan": "积分订单",
    "ChaKanWuLiu": "查看物流",


//integral-order-create

    "LPSLBZ_QCX": "礼品数量不足，请重新支付",
    "JiFenBuZu": "积分不足",
    "ZhiFuXinXi": "支付信息",
    "JiXuGuangGuang": "继续逛逛",
    "QTXSHXX": "请填写收货信息",
    "DuiHuanChengGong": "兑换成功",
    "LPDHQR": "礼品兑换确认",
    "SCCKJ": "市场参考价",
    "LiJiZhiFu": "立即支付",


//integral-order-detail

    "DingDanXiangQing": "订单详情",
    "DingDanZhuangTai": "订单状态",
    "DingDanBianHao": "订单编号",
    "DuiHuanShiJian": "兑换时间",
    "ShouHuoXinXi": "收货信息",
    "HuaFeiJiFen": "花费积分",


//integral-rule

    "QDZJF": "签到赚积分",
    "XDZJF": "下单赚积分",
    "DDWCHKHDYD": "订单完成后可获得与订单金额*",
    "1_MRQD_JL": "1、每日签到，奖励",
    "2_LXQD3T_EW": "2、连续签到3天，额外奖励",
    "3_LXQD7T_EW": "3、连续签到7天，额外奖励",
    "BeiDeJiFen": "倍的积分",
    "DLZJF": "登录赚积分",
    "MeiRiDengLu": "每日登录",
    "SC_JL": "商城，奖励",


//integral-rule-detail

    "GuiZe": "规则",


//integral-sign

    "QianDaoYouLi": "签到有礼",
    "QianDaoGuiZe": "签到规则",
    "YLXQD": "已连续签到",
    "QDCG_NYJLX": "签到成功，您已经连续签到",
    "QDCG_BCHD": "签到成功，本次获得",


//

    "QSRBZ": "请输入备注",
    "DingDanZengPin": "订单赠品",
    "GuiGeBianHao": "规格编号",
    "GuiGe": "规格",


//combined-pay

    "CDDWXFK": "此订单无需付款",
    "YHYEBZ": "用户余额不足！",
    "ZFJEBNW0": "支付金额不能为0！",
    "ZFJEBNDYDD": "支付金额不能大于订单待付金额",
    "DFKJE": "待付款金额",
    "FuKuanFangShi": "付款方式",
    "FuJian": "附件",
    "ZHYFZ": "帐号已复制",
    "GHSKZH": "更换收款账号",
    "SYXXZFQSCF": "使用线下支付请上传付款截图附件，以便财务确认款项！",
    "XXZF_BXXZS": "线下支付,必须选择收款账号",
    "DingDanZhiFu": "订单支付",
    "ZFFS_HDFK": "支付方式：货到付款",
    "BCFKJE": "本次付款金额",
    "YuE": "余额",
    "FuZhiZhangHao": "复制帐号",
    "WSZXCXZFPZ": "未设置小程序支付配置",
    "XCXZFZHJY": "小程序支付账号禁用",
    "SRZFJE": "输入支付金额",
    "FuKuanZhangHu": "付款账户",
    "QueRenZhiFu": "确认支付",
    "ShaoHouZhiFu": "稍后支付",


//order

    "FKJLYTJ_DD": "付款记录已添加，等待财务审核",
    "JiXuGuang": "继续逛",
    "BenCiFuKuan": "本次付款",
    "FuKuanChengGong": "付款成功",
    "ZhiFuFangShi": "支付方式",
    "ZhiFuDingDan": "支付订单",


//order-batch-pay

    "QXXZSXZFDD": "请先选择所需支付的订单",
    "PiLiangFuKuan": "批量付款",
    "FuKuan": "付款",
    "'QuFuKuan'": "去付款",


//order-create-result

    "YingFuJinE": "应付金额",
    "XiaDanShiJian": "下单时间",


//order-deliver

    "SFQRSH": "是否确认收货？",
    "DDQRSH_": "订单确认收货？",
    "DFHSP": "待发货商品",
    "ShangPinShuLiang": "商品数量",
    "ChuKuShiJian": "出库时间",
    "FaHuoShiJian": "发货时间",
    "WuLiuBeiZhu": "物流备注",
    "QueRenShouHuo": "确认收货",


//

    "RYFK_QJSYG": "如已付款，请及时与管理员联系",
    "QSRQXYY": "请输入取消原因",
    "H_DDJZDGB_QJKF": "后，订单将自动关闭，请尽快付款",
    "TuiHuoQingKuang": "退货情况",
    "DingDanZongE": "订单总额",
    "DingDanBeiZhu": "订单备注",
    "FaHuoQingKuang": "发货情况",
    "DingDanFuJian": "订单附件",
    "ShenQingTuiHuo": "申请退货",
    "G1TDSHJL_QRS": "共{num}条待收货记录，确认收货吗？",
    "DaiXiaDan": "代下单",
    "DingDanHao": "订单号",
    "XiaDanFangShi": "下单方式",
    "TuiHuoDan": "退货单",
    "ShangPinZongE": "商品总额",
    "YunFei_ZongZhong": "运费(总重",
    "SF_SPWS": "税费(商品未税",
    "TePiJia": "特批价",
    "FuKuanQingKuang": "付款情况",
    "TiaoJiLu": "条记录",
    "ZaiLaiYiDan": "再来一单",
    "QDSCGDD_": "确定删除该订单？",
    "YiQueRen": "已确认",
    "YiShouHuo": "已收货",
    "DaiFuJinE": "待付金额",
    "YiFuJinE": "已付金额",
    "ZanWuJiLu": "暂无记录",


//

    "SPMC_DDH": "商品名称 / 订单号",
    "FuKuanZhuangTai": "付款状态",
    "FaHuoZhuangTai": "发货状态",
    "QiShiShiJian": "起始时间",
    "ZhongZhiShiJian": "终止时间",


//order-list

    "CaoZuoChengGong": "操作成功",
    "ZBKGM": "暂不可购买",
    "DQDDSPYXJ": "当前订单商品已下架",
    "DQDDBFSPYXJ": "当前订单部分商品已下架",
    "JTJSJSP": "仅添加上架商品",
    "DingHuoDan": "订货单",


//order-log

    "DingDanRiZhi": "订单日志",


//order-logistics

    "FuZhiChengGong": "复制成功",
    "FuZhiShiBai": "复制失败",
    "FuZhi": "复制",
    "BoDa": "拨打",
    "WCWLDHXX": "无此物流单号信息",


//order-pay

    "ZFCS_QCXXD": "支付超时，请重新下单",
    "YQYYCKZF": "已启用预存款支付",
    "BNDYDFKJE": "不能大于待付款金额",
    "ZFJEBNWK": "支付金额不能为空",
    "QXAPFK_FKH": "请先安排付款，付款后截图上传付款信息，以便财务确认款项",
    "DDTJCG_QJS": "订单提交成功，请及时支付",
    "QXZFKFS": "请选择付款方式",
    "SYYEZFH_HX": "使用余额支付后，还需支付",


//

    "FuKuanJiLu": "付款记录",
    "ZWFKJL": "暂无付款记录",
    "FuKuanXiangQing": "付款详情",
    "FuKuanJinE": "付款金额",
    "ZhiDan": "制单",
    "ShenHe": "审核",
    "ZuoFei": "作废",
    "ZuoFeiYuanYin": "作废原因",
    "GLDHD": "关联订货单",
    "HeXiaoJinE": "核销金额",
    "HeXiaoZhuangTai": "核销状态",
    "DanJuZhuangTai": "单据状态",
    "KeHuMingCheng": "客户名称",
    "ZhangDanHao": "账单号",
    "ShouKuanZhangHao": "收款账户",
    "ShouKuanShiJian": "收款时间",
    "FuKuanPingZheng": "付款凭证",
    "GeFuJian": "个附件",


//order-statistics

    "DingDanTongJi": "订单统计",
    "DingHuo": "订货",
    "Bi": "笔",


//order-statistics-detail
    "DDTJMX": "订单统计明细",
    "AGGTJ": "按规格统计",
    "AFLTJ": "按分类统计",
    "DDJE_Y": "订单金额/元",
    "DHSP_Z": "订货商品/种",
    "DingHuoDan_Bi": "订货单/笔",
    "DingHuoShangPin": "订货商品",
    "JinE": "金额",

//refund-list
    "TuiHuo": "退货",
//refund-apply
    "THDHWBC_QD": "退货单还未保存，确定离开？",
    "QSRSQTHJE": "请输入申请退货金额",
    "QSRTHJE": "请输入退货金额",
    "QSRBZXX": "请输入备注信息",
    "YingTuiJinE": "应退金额",
    "TJTHSQ": "提交退货申请",
    "QXXZDHQD": "请先选择订货清单",
    "DingHuoQingDan": "订货清单",
    "SQTHJE": "申请退货金额",
    "DJXZSP": "点击选择商品",
    "TuiHuoJinE": "退货金额",

//refund-detail
    "THDDXQ": "退货订单详情",
    "YiTuiHuo": "已退货",
    "YiTuiKuan": "已退款",
    "THDZT": "退货单状态",
    "TuiHuoDanHao": "退货单号",
    "TuiHuoShiJian": "退货时间",
    "TuiHuoCangKu": "退货仓库",
    "THLXR": "退货联系人",
    "TuiHuoQingDan": "退货清单",
    "TuiKuanZongE": "退款总额",
    "TuiKuanQingKuang": "退款情况",
    "DaiTuiJinE": "待退金额",
    "YiTuiJinE": "已退金额",


//refund-product

    "SPMC_BH_GG": "商品名称 / 编号 / 规格编号 / 条形码",
    "GZD1TJG": "共找到1条结果",
    "JiaRuQingDan": "加入清单",


//refund-result

    "ShenQingChengGong": "申请成功",
    "THDTJCG": "退货单提交成功",
    "ChaKanXiangQing": "查看详情",

//goods-package-floor

    "HDWKS_WDZL": "活动未开始，晚点再来吧",
    "HDYJS_XCZD": "活动已结束，下次早点哦",
    "ZWSZBT": "暂未设置标题",
    "ChaKanGengDuo": "查看更多",


//goods-promotion-floor

    "HCDZSJC": "缓存导致时间差",
    "DLCKCXJ": "登录查看促销价",
    "ZWSZCX": "暂未设置促销",
    "JuKaiShi": "距开始",
    "Song": "送",
    "JuJieShu": "距结束",
    "YiJieShu": "已结束",


//search-floor

    "XiaoXi": "消息",
    "SaoMaXiaDan": "扫码下单",


//home-page-custom

    "WZLCSJ": "未知楼层数据",


//jump-error

    "QXZYSDHAPP": "请下载云上订货APP",
    "QMTYQYGLD": "全面体验企业管理端",
    "XZYSDHAPP": "下载云上订货APP",
    "HQGLDWZTY": "获取管理端完整体验",
    "JXTYDHD": "继续体验订货端",


//notice-detail

    "GongGaoXiangQing": "公告详情",
    "GongGaoFuJian": "公告附件",


//shop-error

    "NFWDYMBCZO": "你访问的页面不存在哦~",
    "KeNengYuanYin": "可能原因",
    "1_ZDZLSRCWD": "1.在地址栏输入错误地址;",
    "2_DJLGQLJ": "2.点击了过期链接。",


//shop-index

    "GuanLiDuan": "管理端",
    "ZhuCe": "注册",
    "HYNTYYSDH": "欢迎您体验云上订货",
    "TYZHSJHDQG": "体验账号数据会定期更新，请勿输入重要信息",
    "TYZHZDNRSJ": "体验账号中的内容数据均为虚构，请勿输入重要信息",
    "ZHZCCG": "账号注册成功",
    "DengLuZhangHao": "登录账号",
    "CSMMW": "初始密码为",
    "GNFYHQL": "给您发优惠券啦",
    "Man1YuanKeYong": "满{num}元可用",
    "LQH1TNKY": "领券后{day}天内可用",
    "YiJianLingQu": "一键领取",
    "GXNHD1ZYHQ": "恭喜您获得1张优惠券",
    "Gong1Yuan": "共1元",
    "LiJiShiYong": "立即使用",


//shop-login

    "NHWDL": "你还未登录",


//shop-notice

    "ShangChengGongGao": "商城公告",
    "ZanWuGongGao": "暂无公告",
    "1GeFuJian": "{num}个附件",


//deposit-detail

    "KouKuan": "扣款",
    "ChongZhi_Kuan": "充值",
    "KouKuanZuoFei": "扣款作废",
    "ChongZhiZuoFei": "充值作废",
    "ZhangHuMingXi": "账户明细",
    "ZhangHuYuE": "账户余额",
    "LiJiChongZhi": "立即充值",


//deposit-recharge

    "CZJEBNXY1Y": "充值金额不能小于1元",
    "QSRZQDSZ": "请输入正确的数值",
    "ZDYJE": "自定义金额",
    "ZWKTXSCZGN": "暂未开通线上充值功能",
    "ShiFuJinE": "实付金额",
    "ZhangHuChongZhi": "账户充值",
    "QXZCZJE": "请选择充值金额",
    "QSRCZJE": "请输入充值金额",
    "QLXNDZSYWY": "请联系您的专属业务员充值",
    "ShouJia": "售价",
    "YiShouQing": "已售罄",
    "ChongZhiJinE": "充值金额",
    "QueRenFuKuan": "确认付款",


//deposit-recharge-result

    "QSDDID": "缺少订单id",
    "ChongZhiChengGong": "充值成功",
    "ChongZhiShiBai": "充值失败",
    "CLZ_QSH...": "处理中，请稍后...",
    "ChongZhiFangShi": "充值方式",
    "ChongZhiShiJian": "充值时间",
    "JYLSH": "交易流水号",
    "FanHuiShangCheng": "返回商城",


//recharge-online

    "ZFSB_QCS": "支付失败，请重试",
    "ZFYQX": "支付已取消",
    "ZFYC_QCS": "支付异常，请重试",


//user

    "LingQuanZhongXin": "领券中心",
    "WeiShiYong": "未使用",
    "YiShiYong": "已使用",
    "ZWYHQ": "暂无优惠券",


//keep-list

    "YiGouMai": "已购买",
    "ChangYongQingDan": "常用清单",
    "WoDeShouCang": "我的收藏",
    "QuGouWuChe": "去购物车",
    "GeShangPin": "个商品",


//login

    "YunShangDingHuo": "云上订货",
    "QSRZH": "请输入账号!",
    "QSRMM": "请输入密码!",
    "QSRDLZH": "请输入登录账号",
    "JiZhuMiMa": "记住密码",
    "WangJiMiMa": "忘记密码?",
    "DengLu": "登录",
    "DLZHBCZ_QL": "登录账号不存在,请联系管理员开通",
    "GQYZHSBJY_": "该企业账号试被禁用,请联系云上订货",
    "GQYZHSYQJS_": "该企业账号试用期结束,请联系云上订货",
    "GQYZHYGQL_": "该企业账号已过期了,需要续费才能使用,请联系云上订货",
    "LiJiTiYan": "立即体验",


//my-collect

    "YiChuChengGong": "移除成功",
    "QueHuo": "缺货",
    "YCSCJ": "移出收藏夹",


//order-message

    "SheZhiChengGong": "设置成功",
    "ZWWDXX": "暂无未读消息",
    "QuanBuYiDu": "全部已读",
    "DingDanXiaoXi": "订单消息",


//

    "YiQiang": "已抢",
    "LiJiLingQu": "立即领取",
    "LingQuanHou": "领券后",
    "TianNeiKeYong": "天内可用",


//

    "QSRSJH": "请输入手机号",
    "QSRYZM": "请输入验证码",
    "QSRXMM": "请输入新密码",
    "QSRZQSJGS": "请输入正确手机格式",
    "ChongZhiMiMa": "重置密码",
    "QSRNDSJHM": "请输入您的手机号码",
    "QSRDXYZ": "请输入短信验证",
    "HQYZM": "获取验证码",
    "HKCXHQ": "后可重新获取",
    "QSR6_20WSZHZ": "请输入6-20位数字或字母的密码",


//share-setting

    "QSRSPJGBL": "请输入商品价格比例",
    "SPJGBLBXDY": "商品价格比例必须大于或等于100，且小于999.99",
    "DPMCZBZCTS": "店铺名称暂不支持特殊符号",
    "LXRZBZCTSF": "联系人暂不支持特殊符号",
    "LXFSZBZCTS": "联系方式暂不支持特殊符号",
    "LXDZZBZCTS": "联系地址暂不支持特殊符号",
    "DPJJZBZCTS": "店铺简介暂不支持特殊符号",
    "QSRDPJJ_ZD": "请输入店铺简介，最多可输入50个字",
    "FenXiangSheZhi": "分享设置",
    "NDWSCSPJG": "您的微商城商品价格",
    "NDDHJ_": "您的订货价×",
    "LXXX_KBJ_": "联系信息(可编辑）",
    "DianPuMingCheng": "店铺名称",
    "JianJie": "简介",
    "QSRDPMC": "请输入店铺名称",
    "LianXiRen": "联系人",
    "QSRLXR": "请输入联系人",
    "LianXiFangShi": "联系方式",
    "QSRLXFS": "请输入联系方式",
    "LianXiDiZhi": "联系地址",
    "QSRLXDZ": "请输入联系地址",


//user

    "WoDeDingDan": "我的订单",
    "ZuiJinLiuLan": "最近浏览",
    "ShouCangShangPin": "收藏商品",
    "BDYYZH_ZC": "绑定已有账号 | 注册新账号",
    "DL_ZC": "登录 | 注册",
    "BangDingShouJi": "绑定手机",
    "TS_BDSJHM_": "提示：绑定手机号码，账号更安全！",
    "ChaKanQuanBu": "查看全部",
    "ShouHuoDiZhi": "收货地址",
    "LianXiWoMen": "联系我们",
    "SheZhi": "设置",
    "QuQianDao": "去签到",
    "JiFenShangCheng": "积分商城",
    "TuiGuangShangPin": "推广商品",
    "QuChaKan": "去查看",
    "JiFenHuanLi": "积分换礼",
    "DYSPBQ": "打印商品标签",


//user-about

    "GYYSDH": "关于云上订货",
    "YQHYSMEWM_": "邀请好友扫描二维码，直接下载安装",
    "DangQianBanBen": "当前版本",


//user-address

    "ZWSHDZ": "暂无收货地址",
    "WoDeDiZhi": "我的地址",
    "ZhiNengShiBie": "智能识别",
    "ZTDZXXKZNS": "粘贴地址信息可智能识别姓名、电话、地址",
    "MoRen": "默认",
//

    "QRSCDZ": "确认删除地址？",
    "SHRXM": "收货人姓名",
    "SJHMHGDDHZ": "手机号码或固定电话至少填写一个",
    "QSRSHRXM": "请输入收货人姓名",
    "ShouJiHaoMa": "手机号码",
    "GuDingDianHua": "固定电话",
    "QSRGDDH": "请输入固定电话",
    "SuoZaiDiQu": "所在地区",
    "QSRSZDQ": "请输入所在地区",
    "QSRXXDZ": "请输入详细地址!",
    "QSRZQDSJHM": "请输入正确的手机号码",
    "QSRZQDGDDH": "请输入正确的固定电话",
    "XiangXiDiZhi": "详细地址",
    "MoRenDiZhi": "默认地址",
    "SWMRDZ": "设为默认地址",
    "BCBSY": "保存并使用",


//user-bing-one

    "QSRSJHM": "请输入手机号码",
    "GYHYZC": "该用户已注册",
    "GSJHMYJZCG": "该手机号码已经注册过",
    "QZJDLJK": "请直接登录即可",
    "QuDengLu": "去登录",
    "BDHKYTGSJH": "绑定后可以通过手机号进行登录，便于您找回密码，提高账户安全性",
    "_BDHKYTGSJ": "*绑定后可以通过手机号登录，便于您找回密码，提高账户安全性",
    "QSRSJYZM": "请输入手机验证码",
    "BDXSJ": "绑定新手机",
    "DXYZM": "短信验证码",
    "XiaYiBu": "下一步",
    "TGCBZ": "跳过此步骤",


//user-bing-two

    "DBDSJHMBCZ": "待绑定手机号码不存在!",
    "QSRDLMM": "请输入登录密码!",
    "_GSJHMYBDY": "*该手机号码已绑定云上订货账号，验证手机登录密码，即可绑定当前帐号",
    "DQZHMMJTBW": "当前账号密码将同步为手机登录密码",
    "DangQianZhangHao": "当前账号",


//

    "QDYJZ": "确定要将这",
    "JSPSCM": "件商品删除吗？",
    "ShanChuChengGong": "删除成功！",


//user-info

    "SJGSCW": "手机格式错误",
    "YXGSCW": "邮箱格式错误",
    "WoDeZhangHao": "我的账号",
    "YouXiang": "邮箱",
    "ShouJi": "手机",
    "XingMing": "姓名",
    "XiuGaiTouXiang": "修改头像",
    "JiBenXinXi": "基本信息",
    "ZhangHaoAnQuan": "账号安全",
    "XiuGaiMiMa": "修改密码",
    "DianJiBangDing": "点击绑定",
    "RYDSHTY_QL": "若已丢失或停用，请立即更换，避免账户被盗",
    "WBZNDZHAQ_": "为保障您的账号安全，请绑定手机",
    "QSRNDXM": "请输入您的姓名",
    "QSRNDDH": "请输入您的电话",
    "QSRNDYX": "请输入邮箱",


//user-setting

    "QCHCHXCXDL": "清除缓存后需重新登录",
    "QingChuHuanCun": "清除缓存",
    "QDYQCHCM": "确定要清除缓存吗？",
    "TuiChuDengLu": "退出登录",
    "QieHuanZhangHao": "切换账号",


//user-supplier

    "LianXi": "联系",
    "GongSiDianHua": "公司电话",
    "DiZhi": "地址",


//vouchers-detail

    "DJQMX": "代金券明细",


//vouchers-list

    "BuKeYong": "不可用",
    "KeYong": "可用",
    "ZWDJQ": "暂无代金券",
    "ZongEDu": "总额度",
    "FaFangShiJian": "发放时间",
    "EDYYW": "额度已用完",
    "QBSPKY": "全部商品可用",


//vouchers-select-list

    "WDDJQ": "我的代金券",
    "KYDJQ": "可用代金券",
    "DJQKDK": "代金券可抵扣",
    "CKKYSP": "查看可用商品",
    "BKYDJQ": "不可用代金券",
    "BKYYY_ZWFH": "不可用原因：暂无符合条件商品",
//goods-detail

    "DQSPWSCGGT": "当前商品未上传规格图，不支持配灯！",
    "BoFangShiPin": "播放视频",
    "Gong1GeGuiGe": "共{num}个规格",
    "YiXuanGuiGe": "已选规格",
    "XuanGuiGe": "选规格",
    "LingQuan": "领券",
    // "Jian":"减",
    "1ZhongCuXiao": "1种促销",
    "1ZhangKeYong": "1张可用",
    "ZuiGaoKeSheng": "最高可省",
    "ShangPinTaoCan": "商品套餐",
    "Gong1GeTaoCan": "共1个套餐",
    "ShangPinCanShu": "商品参数",
    "ZhanKai": "展开",
    "YiShouCang": "已收藏",
    "ShouCang": "收藏",
    "KeFu": "客服",
    "XiangQing": "详情",
    "TuiJian": "推荐",
    "BianHao": "编号",
    "ShangPinXinXi": "商品信息",
    "KLYHQ": "可领优惠券",
    "KuCunBuZu": "库存不足",
    "DuoGuiGe": "多规格",
    "YiShou": "已售",
    "SWBHZ_YS": "售完补货中，已售",
    "CuXiaoShangPin": "促销商品",


//goods-search

    "NHWSRSSNR": "你还未输入搜索内容",
    "SouSuo": "搜索",
    "SSY1XGDSP": "搜索与{searchKey}相关的商品",
    "Yue1GeShangPin": "约{num}个商品",
    "ZuiJinSouSuo": "最近搜索",
    "QingChu": "清除",
    "ZWSSJL": "暂无搜索记录",


//goods-property

    "ZGJGBNDYZD": "最高价格不能低于最低价格",
    "ShangPinBiaoQian": "商品标签",
    "BuGaoYu1": "不高于1",
    "BuDiYu1": "不低于1",
    "FengGe": "风格",


//goods-promotion-list

    "JiJiangKaiShi": "即将开始",
    "QuanBuCuXiao": "全部促销",


//goods-package-list

    "Gong1JianShangPin": "共1件商品",
    "Sheng": "省",


//goods-package-detail

    "GTCZGWCZDS": "该套餐在购物车中的数量已达到限购量，不可加入购物车",
    "GTCKCBZ_BK": "该套餐库存不足，不可加入购物车",
    "JinSheng1Tao": "仅剩1套",
    "XianGou1Tao": "限购1套",
    "TaoCanXiangQing": "套餐详情",
    "DanMaiJia": "单买价",
    "TaoCanJia": "套餐价",
    "HDYJS": "活动已结束",


//goods-message

    "WDL_QXDL": "未登录，请先登陆",


//goods-list-label

    "XinPinShangShi": "新品上市",
    "WDLZH_ZWCX": "未登录账号，暂无促销活动！",
    "YiXiaShangPin": "以下商品",


//goods-list
    "1ZhongGuiGe": "1种规格",
    "YouKuCun": "有库存",

//goods-filter
    "BuGaoYu": "不高于",
    "BuDiYu": "不低于",
    "CuXiao": "促销",
    "ZDYXXZ3G": "最多允许选择3个",
    "GouMai": "购买",

    "Gong1Jian": "共{num}件",

//已订货商品
    "DLHCKJG": "登录后查看价格",
    "M1JZ1J": "满{num}件赠{presentNum}件",
    "CZPF_ZZCXC": "操作频繁，正在重新尝试",
    "XSZJJXZ": "限时直降进行中",
    "YXSPM1YZ1J_": "以下商品{str},加入购物车结算立享优惠！",
    "TiaoXingMa": "条形码",
    "XiangGuanTuiJian": "相关推荐",
    "YSSPBKJRGW": "预售商品不可加入购物车，已为您添加非预售商品！",
    "YSSPBKLJGM": "预售商品不可立即购买，请移除预售商品再重新购买！",
    "SPSLYW": "商品数量有误",
    "ShangPin1QiShou": "商品{num}起售",
    "SPDGSLBNCG": "商品订购数量不能超过{num}",
    "QRYCXZSP": "确认移除选中商品",
    "QRYCSYSXSP": "确认移除所有失效商品",
    "QRYCXZSXSP": "确认移除选中失效商品",
    "M1YZ1J": "满{num}元赠{presentNum}件",
    "M1YJ1Y": "满{price}元减{money}元",
    "M1YD1Z": "满{price}元打{money}折",
    "DDM1YHZ": "订单满{price}元获赠",
    "YiHuoZeng": "已获赠",
    "ZG1YLXM1YZ1": "再购{price}元立享{str}",
    "ZG1JLXM1JZ1": "再购{num}件立享{str}",
    "QCSXSP": "清除失效商品",
    "YiXuanShangPin": "已选商品",
    "QuJieSuan": "去结算",
    "ShiXiaoShangPin": "失效商品",
    "DingDanGuanLi": "订单管理",
    "ZhuangTai": "状态",
    "ShangPinChuKu": "商品出库",
    "DengDaiShouHuo": "等待收货",
    "PeiSongXinXi": "配送信息",
    "FuKuanXinXi": "付款信息",
    "GDFKJL": "更多付款记录",
    "WuLiuFuJian": "物流附件",
    "ZanWuFuJian": "暂无附件",
    "YiFa": "已发",
    "YingFuZongE": "应付总额",
    "FuKuanShiJian": "付款时间",
    "ZhangHuHaoMa": "账户号码",
    "KeYongYuE": "可用余额",
    "TianJiaChengGong": "添加成功",
    "ShanChuDiZhi": "删除地址",
    "QXZSHDZ": "请选择收货地址",
    "YiHuoZengPin": "已获赠品",
    "YiYouHui1Yuan": "已优惠{money}元",
    "QWSFPXX": "请完善发票信息",
    "1ZSP": "{num}种商品",
    "Gong": "共",
    "Jian": "件",
    "ShangPinJinE": "商品金额",
    "YunFeiDaoFu": "运费到付",
    "QSRZQDSHRX": "请输入正确的收货人姓名",
    "QSRZQDXXDZ": "请输入正确的详细地址",
    "DBQ_MYZDDY": "对不起，没有找到对应页面",
    "ShouHuoChengGong": "收货成功",
    "Hi_HuanYingNin": "Hi,欢迎您",
    "YiFaEWuLiu": "已发e物流",
    "NinDeDingDan": "您的订单",
    "ZBNBHBQ": "中不能包含表情",
    "XMBNWK": "姓名不能为空",
    "XiTongSheZhi": "系统设置",
    "ZhangHaoXinXi": "帐号信息",
    "QingShuRuXingMing": "请输入姓名",
    "QWSZL_JZYG": "请完善资料，将助于更好地为您服务",
    "QSRZQDYXGS": "请输入正确的邮箱格式",
    "QTXZQDCWXX": "请填写正确的财务信息，以便您的合作伙伴为您开具发票",
    "GXSHDZCG": "更新收货地址成功",
    "BCSHDZCG": "保存收货地址成功",
    "XinZengDiZhi": "新增地址",
    "QXZYQXSCDS": "请选择要取消收藏的商品",
    "QuXiaoShouCang": "取消收藏",
    "XuanZeGuiGe": "选择规格",
    "CaiWuGuanLi": "财务管理",
    "DuiZhangMingXi": "对账明细",
    "QSRLWJL_JE": "请输入来往记录/金额",
    "YiGouMai1Ci": "已购买{count}次",
    "6_1GouMai": "{time}购买",
    "TZGGXQ": "通知公告详情",
    "ZYXSCSP": "只允许上传视频",
    "SCSPDXBYXC": "上传视频大小不允许超过{size}",


    "YDHSP": "已订货商品",
    "ShangPinPinPai": "商品品牌",
    "ShangPinLieBiao": "商品列表",
    "LingShouJia": "零售价",
    "YouHuiJia": "优惠价",
    "LiJiGouMai": "立即购买",
    "ZhuTu": "主图",
    "WuXianZhi": "无限制",
    "XiaoJi": "小计",
    "SCXZSP": "删除选中商品",
    "QSRDDH": "请输入订单号",
    "QSRDDH_SPMC": "请输入订单号/商品名称",
    "CaoZuo": "操作",
    "WuLiuXinXi": "物流信息",
    "CaoZuoLeiXing": "操作类型",
    "CaoZuoRiZhi": "操作日志",
    "CaoZuoShiJian": "操作时间",
    "ZhangHuMingCheng": "账户名称",
    "KaiHuYinHang": "开户银行",
    "DDTJCG_XZZC": "订单提交成功，现在只差最后一步了",
    "ZhiFuJinE": "支付金额",
    "DZFJE": "待支付金额",
    "QTXYXSMB": "填写一些说明吧",
    "XZSKYHZH": "选择收款银行账户",
    "TiShi": "提示",
    "NHY1GDDGJ1Y": "你还有{num}个订单共计{money}元未结清",
    "QKJQHCKJXX": "欠款结清后才可继续下单",
    "HDDDXX": "核对订单信息",
    "BJSHDZ": "编辑收货地址",
    "TongZhiGongGao": "通知公告",
    "YinHangZhangHao": "银行账号",
    "XiuGaiShouJi": "修改手机",
    "LXRXX": "联系人信息",
    "GSCWXX": "公司财务信息",
    "QSRKHYHMCJ": "请输入开户银行名称及开户支行",
    "QSRYHZH": "请输入银行账号",
    "PiLiangCaoZuo": "批量操作",
    "GouMaiJiLu": "购买记录",
    "TongZhiBiaoTi": "通知标题",
    "FaBuShiJian": "发布时间",
    "XiaoXiLeiXing": "消息类型",
    "XiaoXiNeiRong": "消息内容",
    "JieShouShiJian": "接受时间",
    "QuanBuPinPai": "全部品牌",
    "WXSYS_DHGBJ": "微信扫一扫，订货更便捷",
    "GZWXGZH": "关注微信公众号",
    "FuWuReXian": "服务热线",
    "SouSuoLiShi": "搜索历史",
    "QuanBuShanChu": "全部删除",
    "NH_HYLDYSDH": "您好，欢迎来到{company}！",
    "ZSJDH": "在手机订货",
    "SYS_GZWXGZH": "扫一扫，关注微信公众号",
    "GuanZhuWoMen": "关注我们",
    "QBSPFL": "全部商品分类",
    "QuPeiDeng": "去配灯",
    "KuanXiang": "款项",
    "QSRSPMC_GG_": "请输入商品名称/规格/编号/首字母",
    "NH_HYLDYSD": "您好，欢迎来到云上订货商城！",
    "ReXiao": "热销",
    "20Tiao_Ye": "20 条/页",
    "ShiChangJia_": "市场价：",
    "_134.10Qi": "Desde S/ 134.10",
    "HJ_BHYF": "合计(不含运费)：",
    "QDSCXZSP_": "确定删除选择商品?",
    "QDQCXJSP_": "确定清除下架商品?",
    "DDTJCG_XZZ": "订单提交成功，现在只差最后一步了！",
    "XuanZeZhangHu": "选择账户",
    "DaiZhiFu": "待支付",
    "CaiGouDingDan": "采购订单",
    "DingDanXinXi": "订单信息",
    "TuiChu": "退出",
    "KaiHuMingCheng": "开户名称",
    "QSRKHMC": "请输入开户名称",
    "_QTXZQDCWX": "（请填写正确的财务信息，以便您的合作伙伴为您开具发票）",
    "ShouHuoRen": "收货人",
    "SWMRSHDZ": "设为默认收货地址",
    "TianJiaDiZhi": "添加地址",
    "Gong4GeGuiGe": "共4个规格",
    "Zhi": "只",
    "Kuai": "块",
    "Tou": "头",
    "Xiang": "箱",
    "Ping": "瓶",
    "KaiShiRiQi": "开始日期",
    "JieShuRiQi": "结束日期",
    "YiXuan": "已选",
    "ZhiFuZhuangTai": "支付状态",
    "QDLCKJG": "请登录查看价格",
    // "QDLHCZ":"请登录后操作",
    // "YYZH_QDL":"已有账号，去登录",
    // "ZCXZH":"注册新账户",商
    "YZMBZQ": "验证码不正确",
    "ZhiFuJiLu": "支付记录",
    "ShangPinMingCheng": "商品名称",
    "DingGouShuLiang": "订购数量",
    "TuiHuoShuLiang": "退货数量",
    "QSRTHNR": "请输入退货内容",
    "SCTHSM": "上传退货说明",
    "ShenQingShiJian": "申请时间",
    "ShenQingJinE": "申请金额",
    "TuiHuoYuanYin": "退货原因",
    "TuiHuoShuoMing": "退货说明",
    "TuiDanHao": '退单号',

    "SXCZ_XDCG": "授信充足，下单成功",
    "SXEDBZ_FKHFH": "授信额度不足，付款后发货",
    "CGJKZQ_FKFH": "超过借款周期，付款发货",
    "DDTJCG_XZZCZHYB": "订单提交成功，现在只差最后一步了！",
    "QNZ_NWCFK_FZ": "请您在{time}内完成付款，否则订单会被自动取消。",

    "MianFeiLing": "免费领",
    "M_KMFLQ_HQ": "满{target}元可免费领取，还差{short}元",
    "YHDMFSP": "已获得免费商品",
    "JRMFL": "今日免费领",
    "LingGuangLe": "领光了",
    "KeLingQu": "可领取",

    "QSRZFJE": "请输入支付金额",
    "QNJKFK_WMJKWNCL": "请您尽快付款，我们尽快为您处理订单！",

    "XianXiaTiYanGuan": '线下体验馆',
    "QingXuanZheSheng": '请选择省',
    "QingXuanZheShi": '请选择市',
    "ZhanGuanDiZhi": '展馆地址',
    "YingYeShiJian": '营业时间',
    "ZhanGuanDianHua": '展馆电话',
    "FenXiang": "分享",
    "TiYanGuanJieShao": "体验馆介绍",


    "DPYDY_QSHX": "店铺已打烊，请稍后下单",
    "DaYangShiJian": "打烊时间",
    'IMEI': '串号',

    "FanKuanYuE": "返款余额",
    "FanKuanZhiFu": "返款支付",
    "YiShengZhiFu": "易生支付",
    "JiongDongShouYinTai": "京东收银台",
    "FKYEBZ": "返款余额不足",
    "FanKuanZhangHu": "返款账户",
    "HaiXuZhiFu": "还需支付 {price}",

    "FaPiaoMingXi": "发票明细",
    "FaPiaoZhuShou": "发票助手",
    "WoDeFaPiao": "我的发票",
    "FaPiaoXiangQing": "发票详情",
    "KaiPiaoShiJian": "开票时间",
    "DaiKaiFaPiao": "待开发票",
    "DKFPJE": "待开发票金额",
    "KaiPiaoJinE": "开票金额",
    "GuanLianDanHao": "关联单号",
    "ChuangJianShiJian": "创建时间",
    "ZhiFuPingZheng": "支付凭证",
    "QSRZQNR": "请输入正确内容",
    "QSRTDH_SPMC": "请输入退单号/商品名称",
    "ShangPinRuKu": "商品入库",
    "TuiKuanChuLi": "退款处理",
    "YuanDingDanHao": "原订单号",
    "TuiHuoXinXi": "退货信息",
    "TuiKuanXinXi": "退款信息",
    "TuiKuanZhuangTai": "退款状态",
    "ZWTKJL": "暂无退款记录",
    "GDTKJL": "更多退款记录",
    "DDDKFQRBJQSH": "订单待客服确认报价请稍后",
    "BNSRDYHDTSZF": "不能输入单引号等特殊字符",
    "THSLBNWK": "退货数量不能为空",
    "BZBNBHBQ": "备注不能包含表情",
    "THSLBKDYKTHSL": "退货数量大于可退货数量",

    "DDM_YZ_J": "订单满{target}元赠{number}件",
    "M_JZ_J": "满{target}件赠{number}件",
    "M_JJZ_Y": "满{target}件降至{money}元",
    "YDHJJZ_Y": "原订货价降至{price}元",
    "M_JD_Z": "满{number}件打{percent}折",
    "YDHJD_Z": "原订货价打{percent}折",

    // Mercado Pago支付
    "WDSYT": "我的收银台",
    "ZZSYMPSMJY": "正在使用 Mercado Pago 扫码交易",
    "SKF": "收款方",
    "SYSFK": "扫一扫付款",
    "DDXQ": "订单详情",
    "YUAN": '元',

    // 优惠券
    "GXNHQ_Z_Y_": "恭喜您获取{num}张{price}元{name}",
    "QBLX": "全部类型",
    "QLQZX": "去领券中心",
    "YLYH": "已领优惠",
    "MZTJJKSY": "满足条件即可使用",
    "GXNHD_Z_YHQ": "恭喜您获得{num}张优惠券",
    "G_Y": "共{price}元",
    "XZYHQ": "选择优惠券",
};
export default zh_CN
