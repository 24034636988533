import {message} from 'antd'

const hostname = window.location.hostname;
const prodHost = 'milanhome';
const testHost = 'ysdh168';
const devHost = `http://${hostname}`;
const isCustomHost = (hostname.indexOf(prodHost) === -1 && hostname.indexOf(testHost) === -1 && hostname.indexOf('192.168') === -1);
const UrlUtil = {
    getHost(){
        if (process.env.API_ENV === 'production') {
            return prodHost;
        } else if (process.env.API_ENV === 'test') {
            return testHost;
        } else {
            return devHost;
        }
    },
    getAPI() {
        if (process.env.API_ENV === 'production') {
            return `https://api.${prodHost}.shop`;
        } else if (process.env.API_ENV === 'test') {
            return `http://api.ysdh168.com`;
        } else {
            return `${devHost}:8990`;
        }
    },
    getAccountUrl: (companyID) => {
        if (isCustomHost) {
            return window.location.origin;
        } else if (process.env.API_ENV === 'production') {
            return companyID ? `https://www.${prodHost}.shop` : `https://www.${prodHost}.shop`
        } else if (process.env.API_ENV === 'test') {
            return companyID ? `http://store${companyID}.${testHost}.com` : `http://account.${testHost}.com`
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:8993`;
        }
    },
    getPCShopUrl: (companyID) => {
        if (!companyID) return message.info('缺少企业id');
        if (isCustomHost) {
            return window.location.origin;
        } else if (process.env.API_ENV === 'production') {
            return `https://www.${prodHost}.shop`
        } else if (process.env.API_ENV === 'test') {
            return `http://store${companyID}.${testHost}.com`
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:8997`;
        }
    },
    getPCMgrUrl: (companyID) => {
        if (!companyID) return message.info('缺少企业id');
        if (isCustomHost) {
            return window.location.origin;
        } else if (process.env.API_ENV === 'production') {
            return `https://www.${prodHost}.shop`
        } else if (process.env.API_ENV === 'test') {
            return `http://store${companyID}.${testHost}.com`
        } else if (window.location.href.indexOf('192.168')) {
            return `${devHost}:8990`;
        }
    },
    getMShopUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://m.${prodHost}.shop`
        } else if (process.env.API_ENV === 'test') {
            return `http://m.${testHost}.com`
        } else {
            return `${devHost}:8991`;
        }
    },

    getMMgrUrl() {
        if (process.env.API_ENV === 'production') {
            // return `http://mtest.qipeidd.com`;
            return `https://mgr.${prodHost}.shop`;
        } else if (process.env.API_ENV === 'test') {
            return `http://mgr.${testHost}.com`;
        } else if (process.env.API_ENV === 'development') {
            return `${devHost}:8996`;
        }
    },
    getLightUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://pd.${prodHost}.shop`
        } else if (process.env.API_ENV === 'test') {
            return `http://pd.${testHost}.com`
        } else {
            return `${devHost}:8999`;
        }
    },
    getBitMapUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://point.${prodHost}.shop`
        } else if (process.env.API_ENV === 'test') {
            return `http://point.${testHost}.com`
        } else {
            return `${devHost}:5003`;
        }
    },
};
export default UrlUtil;
