import React, {Fragment} from 'react';
import classnames from 'classnames'
import './style.scss'
import {injectIntl} from 'react-intl';
import StorageUtil from "../../utils/storage-util";
import {LocalConst} from "../../utils/constant";
import {SystemFetch} from "../../services/service";

@injectIntl
class UserMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.ysClient = StorageUtil.getItem(LocalConst.ysClient);
        this.isChildAccount = StorageUtil.getItem(LocalConst.isChildAccount);
        this.childAccountRole = StorageUtil.getItem(LocalConst.childAccountRole);
        this.isSales = this.isChildAccount && this.childAccountRole === 2;
    }

    componentDidMount() {
        this.getUserInfo();
    }

    getUserInfo() {
        return SystemFetch.getUserInfo(null, rs => {
            if (rs.status === 1) {
                StorageUtil.setItem(LocalConst.userInfo, rs.data);
                this.setState({
                    userInfo: rs.data
                });
            }
        })
    };

    toPage = (item) => {
        const {history} = this.props;
        history.push(item.router)
    };

    render() {
        const {pathname, intl} = this.props;
        const {userInfo} = this.state;
        this.intl = intl;
        let menuList = [];
        if (this.ysClient.distributor_parent_client_id_) { // 小B端
            menuList = [
                {
                    name: (this.intl.messages['WoDe'] || '我的'),
                    children: [
                        {
                            name: (this.intl.messages['GeRenZhongXin'] || '个人中心'),
                            router: '/distributor/user'
                        },
                        {
                            name: (this.intl.messages['ShouCangShangPin'] || '收藏商品'),
                            router: '/collect/list'
                        },
                    ]
                },
                {
                    name: (this.intl.messages['DingDanGuanLi'] || '订单管理'),
                    children: [
                        {
                            name: (this.intl.messages['DingHuoDan'] || '订货单'),
                            router: '/distributor/order/list'
                        },
                        {
                            name: (this.intl.messages['TuiHuoDan'] || '退货单'),
                            router: '/distributor/refund/list'
                        },
                    ]
                },
                {
                    name: (this.intl.messages['XiTongSheZhi'] || '系统设置'),
                    children: [{
                        name: (this.intl.messages['ZhangHaoXinXi'] || '帐号信息'),
                        router: '/distributor/account'
                    }, {
                        name: (this.intl.messages['ShouHuoDiZhi'] || '收货地址'),
                        router: '/address'
                    }]
                },
            ];
        } else { // 大B端
            menuList = [
                {
                    name: (this.intl.messages['WoDe'] || '我的'),
                    children: [
                        {
                            name: (this.intl.messages['GeRenZhongXin'] || '个人中心'),
                            router: '/user'
                        },
                        {
                            name: (this.intl.messages['TongZhiGongGao'] || '通知公告'),
                            router: '/notices/list'
                        },
                        {
                            name: (this.intl.messages['DingDanXinXi'] || '订单信息'),
                            router: '/order/message'
                        },
                        {
                            name: (this.intl.messages['ShouCangShangPin'] || '收藏商品'),
                            router: '/collect/list',
                            isHidden: this.isSales,
                        },
                        {
                            name: (this.intl.messages['GouMaiJiLu'] || '购买记录'),
                            router: '/keep/list',
                            isHidden: this.isSales,
                        },
                        {
                            name: (this.intl.messages['YouHuiQuan'] || '优惠券'),
                            router: '/coupon/list'
                        },
                        {
                            name: '员工管理',
                            router: '/subAccount',
                            isHidden: this.isSales,
                        }
                    ]
                },
                {
                    name: (this.intl.messages['DingDanGuanLi'] || '订单管理'),
                    children: [
                        {
                            name: (this.intl.messages['DingHuoDan'] || '订货单'),
                            router: '/order/list'
                        },
                        {
                            name: (this.intl.messages['TuiHuoDan'] || '退货单'),
                            router: '/refund/list'
                        },
                        {
                            name: '售后单',
                            router: '/afterSale/list'
                        },
                        {
                            name: '订单统计',
                            router: '/order/statistics',
                            isHidden: this.isSales,
                        },
                    ]
                },
                {
                    name: (this.intl.messages['CaiWuGuanLi'] || '财务管理'),
                    children: [
                        {
                            name: (this.intl.messages['DuiZhangMingXi'] || '对账明细'),
                            router: '/deposit/detail'
                        },
                        {
                            name: (this.intl.messages['FaPiaoMingXi'] || '发票明细'),
                            router: '/invoice/list'
                        },
                    ]
                },
                // {
                //     name: (this.intl.messages['XiTongSheZhi'] || '系统设置'),
                //     children: [{
                //         name: (this.intl.messages['ZhangHaoXinXi'] || '帐号信息'),
                //         router: '/account'
                //     }, {
                //         name: (this.intl.messages['ShouHuoDiZhi'] || '收货地址'),
                //         router: '/address'
                //     }, ]
                // },
                {
                    name: (this.intl.messages['XiTongSheZhi'] || '系统设置'),
                    children: [{
                        name: (this.intl.messages['ZhangHaoXinXi'] || '帐号信息'),
                        router: '/account'
                    }, {
                        name: (this.intl.messages['ShouHuoDiZhi'] || '收货地址'),
                        router: '/address',
                        isHidden: this.isSales,
                    }]
                },
            ];

            // const deliveryPlaceList = StorageUtil.getItem(LocalConst.deliveryPlaceList);
            // if (deliveryPlaceList.length && !this.isSales) {
            //     deliveryPlaceList.forEach(item => {
            //         menuList[3].children.push({
            //             name: item.name_ + '物流',
            //             router: `/logistics/${item.id_}/${item.name_}物流`
            //         });
            //     });
            // }

            if (!!this.ysClient.enable_distributor_ && !(this.isChildAccount && this.childAccountRole === 2)) {
                menuList.push({
                    name: '经销商管理',
                    children: [{
                        name: '经销商',
                        router: '/distributor/list'
                    }, {
                        name: '经销商订单',
                        router: '/distributor/allOrder/list'
                    }]
                });
            }
        }

        return (
            <section className="user-menu-box">
                <dl>
                    {
                        menuList.map((item, index) => {
                            // console.log('item：', item);
                            if (item.name === '财务管理' && this.isChildAccount && this.childAccountRole === 2) return;
                            return (
                                <Fragment key={index}>
                                    <dt>{item.name}</dt>
                                    {
                                        item.children.map((subItem, sIndex) => {
                                            if (subItem.isHidden) {
                                                return;
                                            }
                                            const cls = classnames({'active': subItem.router === pathname});
                                            return (
                                                <dd className={cls} role="none" key={sIndex}
                                                    onClick={() => this.toPage(subItem)}>{subItem.name}</dd>
                                            )
                                        })
                                    }
                                </Fragment>
                            )
                        })
                    }
                </dl>
            </section>
        )
    }
}

export default UserMenu
